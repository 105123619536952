import { Link } from "react-router-dom"

const PopOutItem = ({data,municipalityUrl})=>{

    return(
            <div
              className="categorybodyIndicator_name"
              key={data.indicator_name}
            >
              <Link
                to={{
                  pathname: "/",
                  search:
                    "?municipality=" +
                    municipalityUrl +
                    "&indicator=" +
                    data.indicator_id,
                }}
              >
                {data.indicator_name}
              </Link>
              {data.about_indicator !== null ? (
                <div className="categorybodyIndicator_nameText">
                  <p>Om {data.indicator_name}:</p>
                  {data.about_indicator}
                </div>
              ) : (
                ""
              )}
            </div>

    )
}
export default PopOutItem