import { AiTwotonePieChart } from "react-icons/ai";
import { iconSet } from "../../images/iconSet";
import PopOutItem from "../menu/PopOutItem";

const PopOutCategory = ({ municipalityUrl, data , rolle }) => {
  let foundIcon = iconSet.find((el) => el.key === data.icon_name);
  const list = [];

  const mapMenuByRole = data.indicator.map((value) => {
    if (municipalityUrl == "2") {
      return (
        <PopOutItem
          key={value.indicator_name}
          data={value}
          municipalityUrl={municipalityUrl}
        />
      )
    }
    else {
      if (rolle === "Alle") {
        list.push(value.indicator_name)
        return (
          <PopOutItem
            key={value.indicator_name}
            data={value}
            municipalityUrl={municipalityUrl}
          />
        );
      }
      else {
      if (value.indicator_roles.filter((role) => role === rolle).length > 0) {
        list.push(value.indicator_name)
        return (
          <PopOutItem
            key={value.indicator_name}
            data={value}
            municipalityUrl={municipalityUrl}
          />
        );
      }
    }
  }
  })




  return (
    <div className="categorybody" key={data.sub_menu_name}>
      <div className="categorybodyElement">
        <header className="categorybodyElementHeader">
          <div>
            {foundIcon && foundIcon !== -1 ? (
              foundIcon.icon
            ) : (
              <AiTwotonePieChart className="favoriteElementLogo" />
            )}
          </div>
          <div className="categorybodyName">{data.sub_menu_name}</div>
        </header>
        <main className="categorybodyElementMain">
          { mapMenuByRole}
        </main>
      </div>
    </div>
  );
};
export default PopOutCategory;
