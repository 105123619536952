import {
    AiTwotonePieChart,
    AiOutlineHeart,
    AiOutlineStop,
  } from "react-icons/ai";
  import { BiNetworkChart, BiDollar } from "react-icons/bi";
  import { BsHouseFill,BsHouseHeartFill } from "react-icons/bs";
  import { FaMoneyCheckAlt } from "react-icons/fa";
  import { FiPercent } from "react-icons/fi";
  import { GiReceiveMoney, GiRailRoad, GiFactory } from "react-icons/gi";
  import { GrUserWorker} from "react-icons/gr";
  import { GoLaw } from "react-icons/go";
  import {
    IoBusinessOutline,
    IoSchoolSharp,
    IoMap,
    IoBagSharp,
    IoCropOutline,
  } from "react-icons/io5";
  import {
    MdFamilyRestroom,
    MdWork,
    MdOutlineQueryStats,
    MdOutlineCoronavirus,
    MdMapsHomeWork,
  } from "react-icons/md";
  import { VscGraphLine } from "react-icons/vsc";
  import { TbCrystalBall } from "react-icons/tb";

  // Import react icon context provider:
  import { IconContext } from "react-icons";
export const iconSet = [
    {
      key: "AiOutlineHeart",
      icon: <AiOutlineHeart className="favoriteElementLogo" />,
    },
    {
      key: "AiOutlineStop",
      icon: <AiOutlineStop className="favoriteElementLogo" />,
    },
    {
      key: "BiNetworkChart",
      icon: <BiNetworkChart className="favoriteElementLogo" />,
    },
    {
      key: "BsHouseFill",
      icon: <BsHouseFill className="favoriteElementLogo" />,
    },
    {
      key: "FaMoneyCheckAlt",
      icon: <FaMoneyCheckAlt className="favoriteElementLogo" />,
    },
    { key: "FiPercent", icon: <FiPercent className="favoriteElementLogo" /> },
    {
      key: "GiReceiveMoney",
      icon: <GiReceiveMoney className="favoriteElementLogo" />,
    },
    { key: "GiRailRoad", icon: <GiRailRoad className="favoriteElementLogo" /> },
    { key: "GoLaw", icon: <GoLaw className="favoriteElementLogo" /> },
    {
      key: "MdMapsHomeWork",
      icon: <MdMapsHomeWork className="favoriteElementLogo" />,
    },
    {
      key: "MdFamilyRestroom",
      icon: <MdFamilyRestroom className="favoriteElementLogo" />,
    },
    {
      key: "IoBusinessOutline",
      icon: <IoBusinessOutline className="favoriteElementLogo" />,
    },
    {
      key: "IoSchoolSharp",
      icon: <IoSchoolSharp className="favoriteElementLogo" />,
    },
    { key: "IoMap", icon: <IoMap className="favoriteElementLogo" /> },
    { key: "IoBagSharp", icon: <IoBagSharp className="favoriteElementLogo" /> },
    {
      key: "IoCropOutline",
      icon: <IoCropOutline className="favoriteElementLogo" />,
    },
    {
      key: "MdFamilyRestroom",
      icon: <MdFamilyRestroom className="favoriteElementLogo" />,
    },
    { key: "MdWork", icon: <MdWork className="favoriteElementLogo" /> },
    {
      key: "MdOutlineQueryStats",
      icon: <MdOutlineQueryStats className="favoriteElementLogo" />,
    },
    {
      key: "MdOutlineCoronavirus",
      icon: <MdOutlineCoronavirus className="favoriteElementLogo" />,
    },
    {
      key: "VscGraphLine",
      icon: <VscGraphLine className="favoriteElementLogo" />,
    },
    {
      key: "BsFillHouseHeartFill",
      icon: <BsHouseHeartFill className="favoriteElementLogo" />,
    },
    {
      key: "BiDollar",
      icon: <BiDollar className="favoriteElementLogo" />,
    },
    {
      key: "GiFactory",
      icon: <GiFactory className="favoriteElementLogo" />,
    },
    {
      key: "GrUserWorker",
      icon: <IconContext.Provider value={{style: {stroke: 'white'} }}><GrUserWorker className="favoriteElementLogo" style={{stroke: "white"}} /></IconContext.Provider>,
    },
    {
      key: "TbCrystalBall",
      icon: <TbCrystalBall className="favoriteElementLogo" />,
    },
  ];