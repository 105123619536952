import React, { } from "react";
import { Link } from "react-router-dom";
import MenuCategory from "./MenuCategory";

import menu from "../../API/menu.json";
import rnfSVG from "../../images/RNF.svg";
import hadeland from "../../images/hadeland.png";
import Sparebank1SVG from "../../images/Sparebank1.svg";


const Menu = (props) => {
    const searchFunction = () => {
        var input, filter, ul, li, a, i, txtValue;
        input = document.getElementById("searchBar");
        filter = input.value.toUpperCase();
        ul = document.getElementById("searchUl");
        li = ul.getElementsByTagName("li");
        for (i = 0; i < li.length; i++) {
            a = li[i].getElementsByTagName("a")[0];
            txtValue = a.textContent || a.innerText;
            if (filter === "") {
                li[i].style.display = "none";
            } 
            else {
                if (txtValue.toUpperCase().indexOf(filter) > -1) {
                    li[i].style.display = "";
                    } 
                else {
                        li[i].style.display = "none";
                    }
            }
        }
    }

    if (props.rolle !== "Alle") {
        console.log("rolle: " + props.rolle)
        // filterList = filterList.filter((roles) => roles.role_id === "1");
    }

    return (
        <div id="menuwrapper" style={{}}>
            <aside style={{ }}>
                <header id="menuheader">
                    {
                    //municipalityURL is a string!!
                    props.municipalityUrl === "1" && (
                        <>
                        {/* <img src={usnSVG} className="sponserLogo" alt="usnSVG" /> */}
                        <img src={rnfSVG} className="sponserLogo" alt="rnfSVG" />
                        <img
                            src={Sparebank1SVG}
                            className="sponserLogo"
                            alt="Sparebank1SVG"
                        />
                        </>
                    )
                    }
                    {props.municipalityUrl === "3" && (
                    <div style={{background:"",color:"black"}}>
                        <img src={hadeland} className="sponserLogo" alt="rnfSVG" />
                    </div>
                    )}
                    <input
                        style={{width:"100%"}}
                        autoComplete="off"
                        type="text"
                        id="searchBar"
                        onKeyUp={searchFunction}
                        onKeyDown={searchFunction}
                        placeholder="Search.."
                    />
                </header>
            
                <section className="menucategoryContainer" style={{ height: props.dimensions.height - 175}}>
                    {menu.site_menus.map((value) => {
                    if (value.site_id.toString() === props.municipalityUrl) {
                        return (
                        <div key={value.name}>
                            <ul id="searchUl">
                            {value.search_indicators.map((value20) => {
                                return (
                                //finnesIndicators.includes(value20.indicator_id) && (
                                <li
                                    style={{ display: "none" }}
                                    key={value20.indicator_id}
                                >
                                    <Link
                                    to={{
                                        pathname: "/",
                                        search:
                                        "?municipality=" +
                                        props.municipalityUrl +
                                        "&indicator=" +
                                        value20.indicator_id,
                                    }}
                                    >
                                    {value20.indicator_name}
                                    </Link>
                                </li>
                                //)
                                )
                            })}
                            </ul>
                            <section className="menuRoot">
                            <ol className="favoriteElement">
                                <Link
                                className="favoriteElementLogo"
                                to={{
                                    pathname: "/",
                                    search:
                                    "?municipality=" + props.municipalityUrl + "&indicator=0",
                                }}
                                >
                                Hjem
                                </Link>
                            </ol>
                            </section>
                            {value.root_menu_element.map((value) => 

                            <MenuCategory key={value.root_menu_name} data={value} municipalityUrl={props.municipalityUrl} rolle={props.rolle}></MenuCategory>
                            )}
                        </div>
                        );
                    } return null;
                    })}
                </section>
            </aside>
        </div>
    )
}


export default Menu;