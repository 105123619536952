import useDimensions from "use-element-dimensions";
import HomeMobile from "./HomeMobile";
import HomeDesktop from "./HomeDesktop";
import { isMobile } from "react-device-detect";
import HomeRewrite from "./home_rewrite";
const HomeFelles = () => {
  const [{ width, height }, refFellesHome] = useDimensions();

  return (
    <main id="HomeFelles" ref={refFellesHome}>
      {width >= 700 ? (
        isMobile ? (
          <HomeMobile />
        ) : (
          // <HomeDesktop dimensions={{ height: height }} />
          <HomeRewrite  dimensions={{ height: height }} />
        )
      ) : (
        <HomeMobile />
      )}
    </main>
  );
};

export default HomeFelles;
